<template>
  <page-layout-maintenance
    :animated="false"
    :type="MAINTENANCE_PAGE_TYPES.NOT_AUTHORIZED"
    subtitle="Click the button below to send an email asking for access to the application"
    title="Not Authorized"
  >
    <template #action-button>
      <!-- Request access email link -->
      <mi-btn
        color="accent"
        type="a"
        @click="getEmailValues"
      >
        Request Access
      </mi-btn>
      <div class="already-confirmed row">
        <p>Already have access or has received the confirmation e-mail?</p>
        <a href="/">Try again</a>
      </div>
    </template>
  </page-layout-maintenance>
</template>

<script>
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { MAINTENANCE_PAGE_TYPES } from '@/constants'

  import PageLayoutMaintenance from '@/layouts/PageLayoutMaintenance.vue'
  import { NOT_AUTHORIZED } from '@/utils/analytics/constants/global/constants'

  export default {
    name: 'NotAuthorized',
    components: { PageLayoutMaintenance },
    data: () => (
      {
        MAINTENANCE_PAGE_TYPES,
        appUrl: process.env.VUE_APP_OAUTH_REDIRECT_URL
      }),
    computed: {
      emailValues: {
        get() {
          return `${ 'mailto:bastian.kopal@man.eu?'
            + 'subject=Request Access to MAN Portfolio Intelligence at '
          }${ this.appUrl }`
        }
      }
    },
    mounted() {
      // Analytics
      recordAnalytics(
        NOT_AUTHORIZED,
        { redirectedFrom: this.$route?.redirectedFrom }
      )
    },
    methods: {
      getEmailValues() {
        window.open(this.emailValues)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .already-confirmed {
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin: 0;

    * {
      font-weight: 400;
      font-size: 18px;
    }

    p {
      margin-bottom: 0;
      text-align: center;
      line-height: 27px;
      color: #5b7085;
    }

    a {
      padding: 8px;
      text-decoration-line: underline;
      line-height: 24px;
      color: #303c49;
    }
  }
</style>
